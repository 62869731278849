<script setup lang="ts">
</script>

<template>
  <aside id="sidebar">
    <slot name="body" />
    <section id="footer">
      <slot name="footer" />
    </section>
  </aside>
</template>

<style scoped lang="postcss">
aside {
  @apply flex flex-col border-r border-black-10 w-fit p-6 pl-12 h-full auto-rows-max bg-white-100;

  section {
    &:not(:last-child) {
      @apply mb-6;
    }

    &#footer {
      @apply mt-auto ml-0.5 pl-4;
    }
  }
}
</style>
